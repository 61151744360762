/* lato-300 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/lato-v20-latin-300.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/lato-v20-latin-300.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* lato-regular - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/lato-v20-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/lato-v20-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* lato-italic - latin */
@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/lato-v20-latin-italic.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/lato-v20-latin-italic.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* lato-700 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/lato-v20-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/lato-v20-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff2') format('woff2'),
		url('RESOURCE/fonts/poppins-v15-latin-300.woff') format('woff');
	font-display: swap;
}

/* playfair-display-regular - latin */
@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v25-latin-regular.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v25-latin-regular.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* playfair-display-700 - latin */
@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('RESOURCE/fonts/playfair-display-v25-latin-700.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
		url('RESOURCE/fonts/playfair-display-v25-latin-700.woff') format('woff');
	/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}