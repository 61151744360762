.gaesteservice {
	margin-top: var(--page-margin-top);

	.img-gaesteservice {

		@media (min-width:768px) {
			float: left;
			padding-right: 30px;
			padding-bottom: 30px;
			max-width: 380px;
			height: auto;
		}

		@media (max-width:768px) {
			margin-bottom: 30px;
			width: 100%;
			height: auto;
		}

	}
}