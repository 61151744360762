.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);
		background-image: url('RESOURCE/img/footer-bg.jpg');



		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--color-primary);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			width: 150px;
			height: auto;
			margin-top: -40px;
		}

		.openings {
			font-style: italic;
			margin-bottom: -10px;

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.list-unstyled {

			li {
				a {
					color: var(--footer-font-color);

					&:before {
						margin-right: 6px;
						font: 12px FontAwesome;
						color: #99aabe;
						content: "\f0da";
					}

					&:hover:before {
						color: var(--color-primary);
					}

					&:hover {
						text-decoration: none;
						color: var(--color-primary);
					}
				}


			}

		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);

				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.weather {
		padding-right: 10px;
		margin-top: 30px;
		text-align: right;

		@media (max-width: 768px) {
			text-align: center;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}