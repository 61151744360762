.immobilienangebote {
	margin-top: var(--page-margin-top);

	.strandkoerbe {

		background-image: url(RESOURCE/img/strandkoerbe.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		margin-bottom: 100px !important;

	}

	.leistungen {
		p {
			line-height: 30px;
			margin-bottom: 0px;
		}
	}

}